<template>
  <div>
    <h1 class="title is-2">Modal tester page</h1>
    <b-button v-for="modal in Object.keys($store.state.modal.modalRegistry)"
              :key="`modal_${modal}`"
              @click="$store.dispatch('openModal', modal)"
              type="is-primary">{{ modal }}
    </b-button>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  methods: {},
}
</script>
